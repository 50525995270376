import client from "../utils/AxiosInesseptor";
const PersonList = async (values) => {
    let { data } = await client.get('/Person/List' , values);
    return data.items;
  };
  const getNationalCode = async (nationalcode) => {
    let { data } = await client.get(`/Dastmozd/GetByNationalCode?nationalcode=${nationalcode}`);
    return data;
  };

export { PersonList, getNationalCode};
