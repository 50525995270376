import React, { useState } from "react";
import SideBar from "../Components/SideBar/SideBar";
import { Link } from "react-router-dom";
// import NavbarDash from "../../Pages/Dashboard/NavbarDashboard/NavbarDash";

const Wrapper = ({ children, text, img, className }) => {
  //   const [open, setOpen] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  return (
    <div
      className={` relative flex flex-col h-screen w-full ${
        showSidebar ? " justify-end items-end" : ""
      }`}
    >
      <SideBar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

      {/* <div className={`flex w-full justify-center items-center`}> */}
      <div className=" flex  justify-end px-5 py-3 items-end bg-cyan-700 h-14 w-full text-zinc-50 font-semibold tracking-wide text-xl">
        Insurance Services{" "}
      </div>
      <div
        className={
          showSidebar
            ? "flex w-10/12 flex-col  bg-slate-50  h-full "
            : "md: bg-slate-50 w-full h-full "
        }
      >
        {children}
      </div>
    </div>
    // </div>
  );
};

export default Wrapper;


