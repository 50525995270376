import * as api from "../apis/ApiTest";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../index";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";


const useApiTest = () => {
  // const navigate = useNavigate();
  return useMutation(api.ApiTest, {
    onSuccess: (response, values) => {
      if (response.status) {
      
       
      }else {
        Swal.fire({
          icon: "error",
          title: "خطا",
          text: response.message,
        });
      }
    },
  });
};
export { useApiTest };
