import React, { useRef, useEffect } from "react";
import Wrapper from "../../Wrapper/Wrapper";
import img from "../../assets/Images/arrow-left.png";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useApiTest } from "../../hook/useApiTest";
import JsonFormatter from "react-json-formatter";
import Loading from "../../Components/Loading/Loading";

const ApiTest = () => {
  const scrolItem = useRef(null);
  const [formValue, setFormValue] = React.useState({
    username: "",
    password: "",
  });

  const validator = () => {
    if (formValue.username === "") {
      toast.error("نام کاربری نمیتواند خالی باشد");
    } else if (formValue.password === "") {
      toast.error(" رمز عبور نمیتواند خالی باشد");
    } else {
      return true;
    }
  };

  const [show, setShow] = React.useState(false);
  const {
    data: apiHandlerData,
    mutate: apiHandler,
    isLoading: apiHandlerLoading,
  } = useApiTest();

useEffect(() =>{
  console.log("hello" + apiHandlerData)
},[apiHandlerData])


  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
    directionStyle: { direction: "ltr" },
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
    if (validator()) {
      apiHandler(formValue);
      setShow(true);

      //   scrolItem.current.scrollIntoView({
      //     behavior: "smooth"
      // });
    }
  };

  useEffect(() => {
    if (show)
      scrolItem.current.scrollIntoView({
        behavior: "smooth",
      });
  }, [show]);

  const [datauser, setdataUser] = React.useState();

  useEffect(() => {
    if (apiHandlerData) {
      setdataUser(apiHandlerData?.result);
    } else {
      console.log("error");
    }
  }, [apiHandlerData]);

  useEffect(() => {
    console.log(datauser);
  }, [datauser]);

  const changeHandler = (e) => {
    setFormValue((prevInput) => {
      return {
        ...prevInput,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <Wrapper>
      <div className="flex  justify-between items-center  border mx-5 py-4 border-cyan-100  mt-5 bg-cyan-50 rounded-lg">
        <p className="font-semibold px-5 tracking-wide text-cyan-900">
          برای تست Api نام کاربری و رمز عبور{" "}
          <span className=" underline underline-offset-8">
            {" "}
            سامانه تامین اجتماعی{" "}
          </span>{" "}
          را وارد کنید :
        </p>
        <div className="flex  justify-end pt-2 items-end">
          <Link to="/dashboard">
            <button className={`flex  `}>
              <p className="text-sm pb-5 text-gray-400"> بازگشت </p>
              <img src={img} alt="" className="w-5 pl-2 pt-1 mx-2" />
            </button>
          </Link>
        </div>
      </div>
      <div className=" mx-5 mt-12 flex flex-col  border border-opacity-80 rounded-3xl bg-sky-50 px-5 ">
        <form className="md:w-[500px] w-3/4 ">
          <div className="mb-6 mt-12">
            <label className="text-sm font-semibold mx-2 text-cyan-900">
              نام کاربری :
            </label>

            <input
              name="username"
              type="text"
              className="placeholder:text-xs form-control block mt-2 w-full md:w-5/6 px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              onChange={changeHandler}
              value={formValue.username}
              placeholder="نام کاربری"
            />
          </div>

          <div className="mb-6 ">
            <label className="text-sm font-semibold mx-2 text-cyan-900">
              رمز عبور :
            </label>
            <input
              name="password"
              type="password"
              className="placeholder:text-xs form-control block mt-2 w-full md:w-5/6 px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              onChange={changeHandler}
              value={formValue.password}
              placeholder="••••••"
            />
          </div>

          <div className="mb-5 text-right">
            {apiHandlerLoading ? null : (
            
                <button
                  onClick={SubmitHandler}
                  type="submit"
                  className="inline-block px-7 py-3 bg-cyan-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                    تست Api
                </button>
            
            )}
          </div>
        </form>

        {show ? (
          <>
            <div
              id="form"
              className="rounded-2xl border-cyan-900 border-opacity-40"
              ref={scrolItem}
            >
              {datauser ? (
                <div className="overflow-y-scroll h-96 rounded-2xl p-5 bg-slate-50" id="#scroll" style={{ direction: "ltr" }}>
                  <JsonFormatter
                    json={JSON.stringify(datauser)}
                    tabWith={4}
                    jsonStyle={jsonStyle}
                  />
                </div>
              ) : (
                <span className="flex flex-col justify-center items-center  text-xl text-gray-500">
                  <p> لطفا چند دقیقه صبر کنید</p>
                  <Loading />
                </span>
              )}
            </div>
          </>
        ) : null}
      </div>
    </Wrapper>
  );
};

export default ApiTest;
