import React, { useEffect } from "react";
import List from "../../Components/List/List";
import { useGetNationalCode } from "../../hook/usePersonList";
import Wrapper from "../../Wrapper/Wrapper";
import { Navigate, useLocation } from "react-router";
import DetailsList from "../../Components/List/DetailsList";
import Loading from "../../Components/Loading/Loading";
import img from "../../assets/Images/arrow-left.png";
import { Link } from "react-router-dom";

const Details = () => {
  const columns = [
    { name: "سال", path: "hisyear" },
    { name: "نام شرکت", path: "rwshname" },
    { name: "نام شعبه", path: "brhname" },
    { name: " فروردین", path: "hismon1", path2: "hiswage1" },
    { name: "اردیبهشت", path: "hismon2", path2: "hiswage2" },
    { name: "خرداد", path: "hismon3", path2: "hiswage3" },
    { name: "تیر", path: "hismon4", path2: "hiswage4" },
    { name: "مرداد", path: "hismon5", path2: "hiswage5" },
    { name: "شهریور", path: "hismon6", path2: "hiswage6" },
    { name: "مهر", path: "hismon7", path2: "hiswage7" },
    { name: "آبان", path: "hismon8", path2: "hiswage8" },
    { name: "آذر", path: "hismon9", path2: "hiswage9" },
    { name: "دی", path: "hismon10", path2: "hiswage10" },
    { name: "بهمن", path: "hismon11", path2: "hiswage11" },
    { name: "اسفند", path: "hismon12", path2: "hiswage12" },
  ];
  const location = useLocation();
  const {
    data: PersonNationalCode,
    mutate: getNationalCode,
    isLoading: personCodeLoading,
  } = useGetNationalCode();

  const [code, setCode] = React.useState("");
  const [name, setName] = React.useState("");

  useEffect(() => {
    if (location.search) {
      let nationalCode = location.search.split("=")[1];
      getNationalCode(nationalCode);
    } else {
      Navigate("/userlist");
    }
  }, [location]);

  useEffect(() => {
    if (PersonNationalCode) setCode(PersonNationalCode.item);
    // console.log(PersonNationalCode)

    console.log(PersonNationalCode?.items?.map((item) => item));
  }, [PersonNationalCode]);

 

  return (
    <div>
      <Wrapper
        text="بازگشت"
        img={img}
        className=" rounded-lg shadow-md bg-gray-200"
      >
        <div className="flex justify-between items-center  border mx-5 py-4 border-cyan-100  mt-5 bg-cyan-50 rounded-lg">
          <p className="font-semibold px-5 text-cyan-900"> نمایش نتیجه برای کد ملی :
            < span className=" text-gray-500 ">   {location?.search ? location?.search.split("=")[1] : "-"}</span>

          
          </p>
          <div className="flex  justify-end pt-2 items-end">
            <Link to="/userlist">
            <button className={`flex  `}>
              <p className="text-sm pb-5 text-gray-400"> بازگشت </p>
              <img src={img} alt="" className="w-5 pl-2 pt-1 mx-2" />
            </button>
            </Link>
          </div>
        </div>
        <DetailsList
          loading={personCodeLoading}
          columns={columns}
          data={PersonNationalCode?.items?.map((item) => item)}
        />
      </Wrapper>
    </div>
  );
};

export default Details;
