import React from "react";
import List from "../../Components/List/List";
import Wrapper from "../../Wrapper/Wrapper";
import { usePersonList } from "../../hook/usePersonList";
import { useNavigate } from "react-router";
import Loading from "../../Components/Loading/Loading";

const UserList = () => {
  const Navigate = useNavigate();

  const columns = [
    { name: "نام", path: "firstName" },
    { name: "نام خانوادگی", path: "lastName" },
    { name: "کد ملی", path: "nationalCode" },
    { name: " محل تولد", path: "issueplaceName" },
    { name: " تاریخ تولد", path: "birthDate" },
  ];

  const detailsBtnHandler = (nationalCode) => {
    Navigate(`/details?nationalCode=${nationalCode}`);
  };

  const { data: personList ,isLoading: personListLoading } = usePersonList({
    page: 1,
    itemcount: 20,
  });
  return (
    <div>
      <Wrapper>
        <List
       
        loading={personListLoading}
          data={personList}
          columns={columns}
          detailsBtn={detailsBtnHandler}
          moreInfoHeader={true}
        />
      </Wrapper>
    </div>
  );
};

export default UserList;
