import React from 'react'

const Card = ({children, className}) => {
  return (
    <div className="w-full" >
        <div className={`${className} h-48 py-10 rounded-lg  mx-5 shadow-md`}>{children} </div>
    </div>
  )
}

export default Card