import client from "../utils/AxiosInesseptor";

const ApiTest = async (values) => {
  let { data } = await client.post(
    `/Dastmozd/Test?username=${values.username}&password=${values.password}`,
    values
  );

  return data;
};

export { ApiTest };
