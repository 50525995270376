import React from "react";
import { ReactDOM } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./index.css";
// -------Rout------------
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard"
import UserList from "./pages/UserList/UserList";
import Details from "./pages/UserList/Details";
import ApiTest from "./pages/ApiTest/ApiTest";

const App = () => {
  return (
    <BrowserRouter>
      <Toaster />
      <Routes>
        
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/userlist" element={<UserList />} />
        <Route path="/details" element={<Details />} />
        <Route path="/apitest" element={<ApiTest />} />



        
      </Routes>
    </BrowserRouter>
  );
};

export default App;