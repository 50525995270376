import React from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useLogin } from "../../hook/useLogin";
import img from "../../Components/Loading/Rolling-Loading.svg";

const Login = () => {
  const [formValue, setFormValue] = React.useState({
    username: "",
    password: "",
  });

  const changeHandler = (e) => {
    setFormValue((prevInput) => {
      return {
        ...prevInput,
        [e.target.name]: e.target.value,
      };
    });
  };
  const { mutate: loginHandler, isLoading: loginLoading } = useLogin();

  const submitHandler = (e) => {
    e.preventDefault();
    loginHandler(formValue);
    // toast.success("ورود با موفقیت انجام شد");
  };
  return (
    <section className="h-screen bg-cyan-50">
      <div className="px-6 h-full text-gray-800">
        <div className="flex flex-col justify-center items-center pt-24 h-screen">
        <div className="flex xl:justify-center lg:justify-center justify-center items-center flex-col md:flex-row h-full g-6">
         
        <h1 className="font-bold text-xl  md:hidden"> ورود به حساب کاربری</h1>
          <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12  md:mb-0">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="w-full p-7"
              alt="Sample"
            />
          </div>
          <div className="xl:ml-20 xl:w-5/12 border-2 border-opacity-30 border-cyan-800 rounded-xl p-5 md:pr-12 lg:w-5/12 md:w-8/12 mb-12  md:mb-0">
            <h1 className="font-bold text-3xl hidden md:block">
              {" "}
              ورود به حساب کاربری
            </h1>

            <form>
              <div className="mb-6 mt-12">
                <label className="text-sm font-semibold mx-2">نام کاربری</label>

                <input
                name="username"
                  type="text"
                  className="placeholder:text-xs form-control block mt-2 w-full md:w-5/6 px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  onChange={changeHandler}
                  value={formValue.username}
                  placeholder="نام کاربری"
                />
              </div>

              <div className="mb-6">
                <label className="text-sm font-semibold mx-2">رمز عبور</label>
                <input
                name="password"
                  type="password"
                  className="placeholder:text-xs form-control block mt-2 w-full md:w-5/6 px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  onChange={changeHandler}
                  value={formValue.password}
                  placeholder="••••••"
                />
              </div>

              <div className="mb-5 text-right">
              {loginLoading ? (
            <button className=" flex justify-center items-center w-19 py-5 px-11 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out" type="submit">
              <img src={img} className="w-7 absolute" alt="pic" />
            </button>
          ) : (
                <button
                  onClick={submitHandler}
                  type="submit"
                  className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  ورود
                </button> )}
              </div>
            </form>
          </div>
        </div>
        <p className="text-gray-400 text-sm">Version 1.7.18</p>
        </div>
      </div>
    </section>
  );
};

export default Login;
