import * as apis from "../apis/PersonList";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../index";
// import Swal from 'sweetalert2';
import toast from "react-hot-toast";

const usePersonList = (values) => {
  return useQuery("PersonList", () => apis.PersonList(values));
};

// const usePersonCode = (values) => {
//   return useQuery("PersonCode", () => apis.PersonCode(values));
// };

const useGetNationalCode = () => {
  return useMutation(apis.getNationalCode, {
    onSuccess: (response, nationalCode) => {
      if (response.status) {
        queryClient.setQueriesData(["PersonNationalCode", nationalCode]);
      } else {
        toast.error(response.message);
      }
    },
  });
};

export { usePersonList, useGetNationalCode };
