import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import closeBtn from "../../assets/Images/closeBtn.svg";

const Sidebar = ({ showSidebar, setShowSidebar }) => {
  const Navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    Navigate("/");
  };

  const Menu = [
    { title: "داشبورد", image: "dashboard", url: "/dashboard" },
    { title: " لیست افراد", image: "customer (2)", url: "/userlist" },
    { title: "تست Api", image: "test", url: "/apitest" },

    {
      title: "خروج",
      image: "logout 1",
      url: "/",
    },
  ];

  return (
    <div className="flex flex-col   relative">
      <div
        className={`top-0 right-0 w-1/6 bg-cyan-700 ease-in-out duration-700 text-white fixed h-full z-40 ${
          showSidebar ? "translate-x-0 " : "translate-x-full"
        }`}
      >
        {/* <div className="flex justify-center items-center"> */}
        {/* <h1
            className="md:w-48 w-20 md:text-lg text-sm font-bold mt-12 text-zinc-100 mx-5"
            style={{ direction: "ltr" }}
            >
            Insurance Services{" "}
          </h1> */}
        {/* </div> */}

        <ul className={`pt-10 flex flex-col justify-center `}>
          {Menu.map((menu, index) => (
            <li
              onClick={() => Navigate(menu.url)}
              key={index}
              className="flex items-center gap-x-4 cursor-pointer text-zinc-100 font-semibold tracking-wide whitespace-nowrap mt-5 p-2 hover:bg-opacity-60 hover:backdrop-filter hover:bg-white hover:backdrop-blur-lg hover:text-white hover:rounded-md hover:mx-2"
            >
              <img
                alt="icon"
                src={require("../../assets/Images/" + menu.image + ".png")}
                className="w-9 text-sky-50  mx-2 "
              />

              <span className={` origin-right duration-200 hidden md:block   `}>
                {menu.title}
              </span>
            </li>
          ))}
          <div className="flex justify-center items" >
          <li className="flex justify-center items absolute bottom-0 text-gray-300 text-sm md:px-0 px-5">Version 1.7.18</li>
          </div>
        </ul>
      </div>

      {showSidebar ? (
        <button
          className="flex text-xl text-white items-center cursor-pointer top-3 hover:text-red-500  hover:font-bold fixed right-5 z-50"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <p className=" text-2xl">x</p>
          {/* <img className="w-7 h-7 text-white hover:text-red-500 active:scale-95 " src={closeBtn} alt="close btn" /> */}
        </button>
      ) : (
        <svg
          onClick={() => setShowSidebar(!showSidebar)}
          className="fixed z-30 flex items-center cursor-pointer right-10 top-2"
          fill="#ffff"
          viewBox="0 0 100 80"
          width="40"
          height="40"
        >
          <rect width="100" height="10"></rect>
          <rect y="30" width="100" height="10"></rect>
          <rect y="60" width="100" height="10"></rect>
        </svg>
      )}
    </div>
  );
};
export default Sidebar;
