import React from "react";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading";

const List = ({
  name,
  data,
  columns,
  className,
  detailsBtn,
  moreInfoHeader,
  loading
}) => {
  return (
    <div className="relative w-full p-5  direction shadow-sm border-none rounded-md overflow-auto max-w-full">
      <table className="w-full text-sm h-fit  text-gray-500 border-collapse mt-5 bg-cyan-50   border border-zinc-400">
        <thead className="border border-zinc-400">
          <tr className="">
            <th>ردیف</th>
            {columns?.map((item) => (
              <th
                key={item.path}
                className={`${className}text-rose-800 bg-blue-200 mt-5 border-t  border-gray-400 py-4`}
              >
                {item.name}
              </th>
            ))}
            {moreInfoHeader && (
              <th className=" bg-blue-200 mt-5 border-t  border-gray-400 py-4">
                اطلاعات بیشتر
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          
            {loading ? (
              <tr>
                <td colSpan={10}>
                  <Loading   />
                </td>
              </tr>
            ) : (  
          data?.map((rowData, index) => (
            <tr key={index} className="h-20 bg-white border-b  border-gray-200">
              <th className="px-2">{index + 1}</th>
              {columns?.map(({ path, path2 }) => {
                return (
                  <>
                    <td
                      style={{ minWidth: "50px" }}
                      className=" text-slate-700 font-medium whitespace-nowrap text-center border-r px-5"
                      key={path}
                    >
               {rowData[path]}
                    </td>
                  </>
                );
              })}
              {detailsBtn && (
                <td className="flex justify-center items-center mt-5">
                  {/* <Link to="/details"> */}
                  <button
                    className="shadow-md rounded-md text-gray-800 px-4 py-2 text-xs"
                    onClick={() => {
                      detailsBtn(rowData["nationalCode"]);
                    }}
                  >
                    <p className="flex">جزییات</p>
                  </button>
                  {/* </Link> */}
                </td>
              )}
            </tr>
          // )
         ) ))}
          <div className="flex items-end justify-end w-full"></div>
        </tbody>
      </table>
    </div>
  );
};
export default List;
