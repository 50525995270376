import React from "react";
import loadingUI from "./loadding2.svg";
const Loading = (className) => {
  return (
    <div className="loading flex justify-center items-center">
      <img src={loadingUI} alt="loading ..." className={`${className} w-20 bg-transparent`} />
    </div>
  );
};

export default Loading;
