import client from "../utils/AxiosInesseptor";
// import Swal from "sweetalert2";

const getMonitoringDataChart = async () => {
    let { data } = await client.get("/Dashboard/MonitoringDataChart");
    if (!data.status) {
        // Swal.fire({
        //     icon: "error",
        //     title: "خطا",
        //     text: data.message,
        // });
        return;
    }
    return data.items;
}
const getDataMonintoring = async () => {
    let { data } = await client.get("/Dashboard/DataMonintoring");
    if (!data.status) {
        // Swal.fire({
        //     icon: "error",
        //     title: "خطا",
        //     text: data.message,
        // });
        return;
    }
    return data.items;
}
export { getMonitoringDataChart, getDataMonintoring }