import React, { useEffect, useState } from "react";
import Card from "../../Components/Card/Card";
import Wrapper from "../../Wrapper/Wrapper";
import day from "../../assets/Images/24-hours.png";
import month from "../../assets/Images/month.png";
import total from "../../assets/Images/all.png";
import Loading from "../../Components/Loading/Loading";

import Chart from "react-apexcharts";
import {
  useMonitoringDataChart,
  useDataMonintoring,
} from "../../hook/useDashboardCharts";

const Dashboard = () => {
  // const monitoringDataChart = {
  //   0: 0,
  //   1: 0,
  //   2: 11,
  //   3: 0,
  //   4: 0,
  //   5: 0,
  //   6: 20,
  //   7: 0,
  //   8: 0,
  //   9: 10,
  //   10: 0,
  //   11: 1,
  //   12: 0,
  //   13: 0,
  //   14: 8,
  //   15: 0,
  //   16: 13,
  //   17: 0,
  //   18: 12,
  //   19: 0,
  //   20: 0,
  //   21: 0,
  //   22: 14,
  //   23: 0
  // }
  const { data: monitoringDataChart, isLoading: monitoringDataChartLoading } =
    useMonitoringDataChart();
  const { data: dataMonintoring, isLoading: dataMonintoringLoading } =
    useDataMonintoring();
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        name: "ساعت",
        categories: [],
      },
    },
    series: [],
  });

  useEffect(() => {
    if (monitoringDataChart) {
      setChartData({
        options: {
          ...chartData.options,
          xaxis: {
            ...chartData.options.xaxis,
            categories: Object.keys(monitoringDataChart),
          },
        },
        series: [
          {
            name: "ساعت",
            data: Object.keys(monitoringDataChart).map(
              (item) => monitoringDataChart[item]
            ),
          },
        ],
      });
    }
  }, [monitoringDataChart]);

  return (
    <Wrapper>
      <div className="flex flex-col w-full">
        {monitoringDataChartLoading ? (
          <tr>
            <td className="flex justify-center items-center">
              <Loading />
            </td>
          </tr>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 mt-9 w-10/12 gap-5 place-items-center mx-auto">
            {dataMonintoring && !dataMonintoringLoading && (
              <>
                <Card className="flex flex-col justify-center bg-cyan-50">
                  <div className="text-center flex justify-start item-center">
                    <img src={day} alt="pic" className="w-16 h-16 mx-3" />
                    <p className="flex justify-end text-3 mx-3 font-semibold text-cyan-800 mt-4 ">
                      {` درخواست ${Object.keys(dataMonintoring)[0]}`} :
                    </p>
                  </div>
                  <p className="font-bold mt-5 flex justify-end ml-9 text-cyan-800 text-5xl">
                    {dataMonintoring[Object.keys(dataMonintoring)[0]]}
                  </p>
                </Card>
                <Card className="flex flex-col justify-center mt-5 md:mt-0 bg-cyan-600">
                  <div className="text-center flex justify-start item-center">
                    <img src={month} alt="pic" className="w-16 h-16 mx-5 " />
                    <p className="flex justify-end text-3 mx-2 font-semibold text-zinc-100 mt-4 ">
                      {`درخواست ${Object.keys(dataMonintoring)[1]}`} :
                    </p>
                  </div>
                  <p className="font-bold mt-5 flex justify-end ml-9 text-zinc-100 text-5xl">
                    {dataMonintoring[Object.keys(dataMonintoring)[1]]}
                  </p>
                </Card>
                <Card className="flex flex-col justify-center  mt-5 md:mt-0 bg-cyan-50">
                  <div className="text-center flex justify-start item-center ">
                    <img src={total} alt="pic" className="w-16 h-16 mx-5" />
                    <p className="flex justify-end text-3 mx-3 font-semibold text-cyan-800 mt-4">
                      {` ${Object.keys(dataMonintoring)[2]}`} :
                    </p>
                  </div>
                  <p className="font-bold mt-5 flex justify-end ml-9 text-cyan-800 text-5xl ">
                    {dataMonintoring[Object.keys(dataMonintoring)[2]]}
                  </p>
                </Card>
              </>
            )}
          </div>
        )}
        <div className=" flex flex-col w-10/12 border-4 rounded-xl border-opacity-30 mt-5 border-gray-400 md:p-12 px-14 mx-auto">
         <p className=" pb-7 pr-2 font-semibold text-cyan-900 text-2xl"> تعداد درخواست های روز جاری به تفکیک ساعت</p>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            width="100%"
            height={"300px"}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default Dashboard;
