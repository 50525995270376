import * as apis from '../apis/Dasboard';
import { useQuery } from 'react-query';


const useMonitoringDataChart = () => {
    return useQuery('DasboardMonitoringDataChart', () => apis.getMonitoringDataChart());
}
const useDataMonintoring = () => {
    return useQuery('DasboardMonitoringData', () => apis.getDataMonintoring());
}

export { useMonitoringDataChart, useDataMonintoring }
