import * as api from "../apis/Login";
import { useMutation } from "react-query";
import { queryClient } from "../index";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";


const useLogin = () => {
  const navigate = useNavigate();
  return useMutation(api.login, {
    onSuccess: (response, values) => {
      if (response.status) {
        queryClient.setQueryData("login", {
     
        });
        navigate("/dashboard")
        toast.success("ورود با موفقیت انجام شد")
      }else {
        Swal.fire({
          icon: "error",
          title: "خطا",
          text: "نام کاربری یا رمز عبور اشتباه می باشد",
        });
      }
    },
  });
};
export { useLogin };
